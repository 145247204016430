<template>
      <div class="container">
        <div class="col-xs-12 col-lg-10 col-lg-offset-1">
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-4">
              <div class="home-bubble lavender">
                <router-link to="/events" title="Training Schedule">
                
                  <span class="title"><img style="width:60px;margin-bottom:5px;" src="../assets/images/scheduleicon.png">Training Schedule</span> 
                  <!-- <span class="text">Check out and sign up for our training events!</span> -->
                </router-link>
                
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4">
              <div class="home-bubble lavender">
                <router-link to="/TrainingPresentations" title="Training Topics">
                  <span class="title"><img style="width:60px;margin-bottom:5px;" src="../assets/images/trainingicon.png">Training Topics</span> 
                  <!-- <span class="text">Click here to see what courses Jacinta offers</span> -->
                </router-link>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4">
              <div class="home-bubble lavender">
                <router-link to="/SummerSessions" title="Summer Sessions">
                  <span class="title"><img style="width:60px;margin-bottom:5px;" src="../assets/images/summericon.png">Summer Sessions</span> 
                  <!-- <span class="text">Click here to sign up for this summer's sessions</span> -->
                </router-link>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4">
              <div class="home-bubble lavender">
                <router-link to="/Blog" title="Jacinta's Blog">
                  <span class="title"><img style="width:60px;margin-bottom:5px;" src="../assets/images/blogicon.png">Blog</span> 
                  <!-- <span class="text">Videos, Blogs, & Podcasts by Jacinta</span> -->
                </router-link>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4">
              <div class="home-bubble lavender">
                <router-link to="/InnovationForLawyers" title="Innovation for Lawyers">
                  <span class="title"><img style="width:60px;margin-bottom:5px;" src="../assets/images/innovateicon.png">Innovation for&nbsp;Lawyers</span> 
                  <!-- <span class="text">Content, tools and resources for lawyers</span> -->
                </router-link>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-4">
              <div class="home-bubble lavender">
                <router-link to="/AboutJacinta" title="About">
                  <span class="title"><img style="width:60px;margin-bottom:5px;" src="../assets/images/questionicon.png">About</span> 
                  <!-- <span class="text">Click here to learn more about Jacinta Gallant</span> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'Body',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>