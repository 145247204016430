<template>
  <div class="home">
    <Environment v-if="environment != 'production'" />
    <Header class="indexheader"/>
    <Body class="mainindexcontentcontainer"/>
    <Footer/>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Body from '../components/Body.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Body,
    Footer
  },
  setup() {
    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
      environment
    }
  }
}
</script>

<style lang="scss">
  // @import "../src/assets/styles/css/print.css";
  // @import "../src/assets/styles/scss/_variables.scss";
  // @import "../src/assets/styles/scss/_base.scss";
  // @import "../src/assets/styles/scss/_components.scss";
  // @import "../src/assets/styles/scss/_layout.scss";
  // @import "../src/assets/styles/scss/_mediaqueries.scss";
  // @import "../src/assets/styles/scss/_mixins.scss";
</style>